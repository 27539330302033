.rsd-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  margin: 10px 0px;
}

.rsd-left-siderbar-container {
  height: 86vh;
  position: sticky;
  top: 80px;
}

.rsd-left-siderbar-main {
  height: 75vh;
  overflow-y: scroll;
}

@media screen and (max-width: 899px) {
  .rsd-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .rsd-left-siderbar-container {
    position: inherit;
  }
}