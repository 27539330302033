/* Tootlbar container */
.toolbar_container {
  position: sticky;
  top: 64px;
  z-index: 9;
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
  padding: 8px 0;
}

@media screen and (max-width: 600px) {
  .toolbar_container {
    top: 48px;
  }
}

/* FAB button add */
.fab_add {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

/* without-data-container */
.without-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

/* Skeleton Loaders */
.section-index-admin div{
  margin-bottom: 5px;
}

/* List cities */

.list_item_container {
  display: grid;
  grid-template-columns: 11fr 1fr;
  gap: 10px;
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 12px !important;
}

@media screen and (max-width: 600px) {
  .list_item_container {
    grid-template-columns: 1fr;
  }

  .list_item_actions {
    border-left: 0;
    padding-left: 0;
  }
}

.list_item_data {
  font-weight: bold;
  display: grid;
  grid-template-columns: 1fr 11fr;
  align-items: center;
}

.list_item_actions {
  border-left: 1px solid #ddd;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .list_item_actions {
    border-left: 0px;
    padding-left: 0px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
  }
}

/* Grid Admin Cards */

.grid-admin-container {
  display: grid;
  gap: 24px;
}

@media screen and (min-width: 0px) {
  .grid-admin-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 600px) {
  .grid-admin-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .grid-admin-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .grid-admin-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Grid Admin Cards */

.grid-admin-product-container {
  display: grid;
  gap: 24px;
}

@media screen and (min-width: 0px) {
  .grid-admin-product-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .grid-admin-product-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1600px) {
  .grid-admin-product-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Real State Development Image Gallery */

.admin-image-gallery {
  display: grid;
  /*grid-template-columns: repeat(2, 1fr);*/
  gap: 24px;
  padding: 16px;
}

@media screen and (min-width: 0px) {
  .two-columns {
    grid-template-columns: repeat(1, 1fr);
  }

  .list-container {
    order: 2;
  }

  .form-container {
    order: 1;
  }
}

@media screen and (min-width: 900px) {
  .two-columns {
    grid-template-columns: repeat(2, 1fr);
  }

  .list-container {
    order: 1;
  }

  .form-container {
    order: 2;
  }
}

/* Real State Development Amenities */

.admin-rsd-amenities {
  display: grid;
  /*grid-template-columns: repeat(2, 1fr);*/
  gap: 24px;
  padding: 16px;
}

@media screen and (min-width: 0px) {
  .admin-rsd-amenities {
    grid-template-columns: repeat(1, 1fr);
  }

  .list-container-rsd-amenities {
    order: 2;
  }

  .form-container-rsd-amenities {
    order: 1;
  }
}

@media screen and (min-width: 900px) {
  .admin-rsd-amenities {
    grid-template-columns: repeat(2, 1fr);
  }

  .list-container-rsd-amenities {
    order: 1;
  }

  .form-container-rsd-amenities {
    order: 2;
  }
}

/* Banners */

.admin-banners-basic-actions {
  display: grid;
  /*grid-template-columns: repeat(2, 1fr);*/
  gap: 24px;
  padding: 16px;
}

.admin-banners-column {
  height: 90vh;
  overflow-y: scroll;
}

.admin-banners-title-actions {
  display: grid;
  gap: 24px;
  grid-template-columns: 11fr 1fr;
}

@media screen and (min-width: 0px) {
  .admin-banners-basic-actions {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .admin-banners-basic-actions {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* JSON editor */
.jsoneditor {
  height: 70vh !important;
}