.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Normalice headers (h1, h2, h3, h4, h5, h6) */
h5 {
  margin: 0;
  padding: 0;
}

body {
  background-color: #fbfbfb !important;
}

/* Image Manager */

.im-main-container {
  display: grid;
  gap: 20px;
  grid-template-columns: 100%;
  width: 100%;
  height: 100%;
}

.im-images-container {
  display: grid;
  gap: 10px;
}

.im-image-categories-container {
  display: grid;
  gap: 20px;
  /*grid-template-columns: 2fr 1fr;*/
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 0px) {
  .im-images-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .im-image-categories-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 600px) {
  .im-images-container {
    grid-template-columns: repeat(3, 1fr);
  } 

  .im-image-categories-container {
    grid-template-columns: 2fr 1fr;;
  }
}

@media screen and (min-width: 900px) {
  .im-images-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .im-images-container {
    grid-template-columns: repeat(5, 1fr);
  }
}

.im-images-toobox-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.im-image-category, .im-image-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  text-align: center;
}

.im-image-category-selected, .im-image-card-selected {
  border: 1px solid rgb(247, 151, 49);
  background-color: rgba(247, 151, 49, 0.15);
}

.im-image-category span {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.im-image-card img {
  max-width: 90%;
}

.im-images-actions-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0px 8px;
}

.im-images-actions-upload, .im-images-actions-delete {
  text-align: center;
}

.im-images-actions-upload span, .im-images-actions-delete span {
  font-size: 13px;
}


/* Builder Schema Structured Data Styles  */
.ds-field-container {
  margin-bottom: 10px;
  margin-left: 1rem;
  padding: 10px;
  border: 1px solid #dadfe4;
  border-radius: 4px;
  background: #f8f9fa;
}

.ds-field-body {
  display: flex;
}

.ds-field-body input {
  border: 1px solid #7f868d;
  border-radius: 4px;
  padding: 6px 8px;
}

.ds-field-body input:nth-child(1) {
  margin-right: 10px;
}